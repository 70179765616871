import {
  headerName,
  headerText
} from "@/model/common/dataTable/dataTableConst";

import { searchableProperty } from "@/model/record/recordModel";

import { isPublicAccess } from "@/services/config/configService";

/**
 * Field Name
 * @type {Readonly<{extension: string, flags: string, hasImage: string, recordTypeId: string, recordType: string, creationDate: string, modificationDate:string, version: string, versionDate: string, pageCount: string, owner: string, createdBy: string, name: string, id: string, category: string}>}
 */
const fieldName = Object.freeze({
  id: "id",
  name: "name",
  category: "category",
  createdBy: "createdBy",
  creationDate: "creationDate",
  modificationDate: "modificationDate",
  extension: "extension",
  flags: "flags",
  hasImage: "hasImage",
  owner: "owner",
  pageCount: "pageCount",
  recordType: "recordType",
  recordTypeId: "recordTypeId",
  state: "state",
  version: "version",
  versionDate: "versionDate"
});

/**
 * Field Label
 * @type {Readonly<{extension: string, flags: string, hasImage: string, recordType: string, creationDate: string, modificationDate: string, version: string, versionDate: string, pageCount: string, owner: string, createdBy: string, name: string, id: string, category: string}>}
 */
const fieldLabel = Object.freeze({
  id: "Id",
  name: "Name",
  category: "Category",
  createdBy: "Created By",
  creationDate: "Created On",
  modificationDate: "Modified On",
  extension: "Extension",
  flags: "Flags",
  hasImage: "Has Image",
  owner: "Owner",
  pageCount: "Pages",
  recordType: "Type",
  recordTypeId: "Type Id",
  state: "State",
  version: "Version",
  versionDate: "Version Date"
});

/**
 * Map of Documents' Headers & Searchable Record Properties
 * @type {{headerValue: string, propName: string}[]}
 */
const mapHeadersSearchableRecordProps = Object.freeze([
  { headerValue: fieldName.recordType, propName: searchableProperty.typeId },
  { headerValue: fieldName.id, propName: searchableProperty.id },
  { headerValue: fieldName.name, propName: searchableProperty.name },
  { headerValue: fieldName.pageCount, propName: searchableProperty.pages },
  {
    headerValue: fieldName.creationDate,
    propName: searchableProperty.createdDateFrom
  },
  { headerValue: fieldName.createdBy, propName: searchableProperty.creator },
  {
    headerValue: fieldName.modificationDate,
    propName: searchableProperty.modifiedDateFrom
  },
  { headerValue: fieldName.owner, propName: searchableProperty.owner },
  { headerValue: fieldName.extension, propName: searchableProperty.extension },
  { headerValue: fieldName.version, propName: searchableProperty.version },
  { headerValue: fieldName.state, propName: searchableProperty.stateId }
]);

/**
 * DataTable Documents headers
 * @type {{text: string, value: string, visible: boolean}[]}
 */
const headers = Object.freeze([
  {
    text: fieldLabel.recordType,
    value: fieldName.recordType,
    visible: true
  },
  {
    text: fieldLabel.id,
    value: fieldName.id,
    visible: false
  },
  {
    text: fieldLabel.name,
    value: fieldName.name,
    visible: true
  },
  {
    text: fieldLabel.version,
    value: fieldName.version,
    visible: false
  },
  {
    text: fieldLabel.pageCount,
    value: fieldName.pageCount,
    visible: true
  },
  {
    text: fieldLabel.creationDate,
    value: fieldName.creationDate,
    visible: true
  },
  {
    text: fieldLabel.createdBy,
    value: fieldName.createdBy,
    visible: !isPublicAccess
  },
  {
    text: fieldLabel.modificationDate,
    value: fieldName.modificationDate,
    visible: false
  },
  {
    text: fieldLabel.owner,
    value: fieldName.owner,
    visible: !isPublicAccess
  },
  {
    text: fieldLabel.extension,
    value: fieldName.extension,
    visible: false
  },
  {
    text: fieldLabel.state,
    value: fieldName.state,
    visible: false
  },
  {
    text: headerText.actions,
    value: headerName.actions,
    sortable: false,
    align: "end",
    visible: true
  }
]);

const fieldDownloadType = Object.freeze({
  asItIs: "asItIs",
  asPdf: "asPdf"
});

export {
  fieldName,
  fieldLabel,
  fieldDownloadType,
  headers,
  mapHeadersSearchableRecordProps
};
