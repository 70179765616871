// services
import {
  getStoredSelectedModuleItemId,
  getStoredSelectedModuleExpandedId
} from "@/services/folder/folderModuleService";

// vuex
import { createNamespacedHelpers } from "vuex";
const {
  mapState,
  mapMutations,
  mapGetters,
  mapActions
} = createNamespacedHelpers("folder");

// store
import {
  APPEND_RECORDS,
  SET_DRAWER_MINI,
  SET_LOCAL_UPLOADED_FILE,
  SET_MOVED_RECORD,
  SET_MOVED_RECORDS,
  SET_RECORD_DELETE_STATUS,
  SET_RECORDS,
  UPDATE_RECORD_LIST_BY_ID
} from "@/store/shared/mutationTypes/record/record-mutation-types";

// mixins
import { moduleItemsDrawerMixin } from "../../shared/base/drawer/moduleItemsDrawerMixin";
import { browsableFolderMixin } from "../../shared/browsable/browsableFolderMixin";
import { badgeableRecordMixin } from "../../shared/record/badgeableRecordMixin";
import { searchableMixin } from "@/mixins/shared/searchable/searchableMixin";

/**
 * folderDrawerMixin
 */
export const folderDrawerMixin = {
  mixins: [
    moduleItemsDrawerMixin,
    browsableFolderMixin,
    badgeableRecordMixin,
    searchableMixin
  ],
  computed: {
    ...mapState({
      records: "records",
      breadcrumbs: "breadcrumbs",
      record: "record",
      movedRecord: "movedRecord",
      movedRecords: "batchMovedRecords",
      recordMode: "recordMode",
      recordDeleted: "recordDeleted",
      fetchRecordListById: "fetchRecordListById"
    }),

    /**
     * Overwrite moduleItemsDrawerMixin Getters
     */
    ...mapGetters(["drawerMini", "selectedModuleItemId"]),

    /**
     * Overwrite (record) items
     * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}[]}
     */
    items() {
      if (this.searchDrawerItemName) {
        return this.records?.filter(el => {
          return el.name
            .toLowerCase()
            .includes(this.searchDrawerItemName.toLowerCase());
        });
      }

      return this.records ?? [];
    },

    /**
     * Overwrite suggested Select (Record) Module Item id
     * @return {Number|number}
     */
    defaultSelectModuleItemId() {
      return this.lastBreadcrumbId >= 0
        ? this.lastBreadcrumbId
        : this.itemCount > 0
        ? this.items[0].id
        : 0;
    }
  },
  methods: {
    ...mapActions({
      setChildRecords: "setChildRecords",
      setBreadcrumbs: "setBreadcrumbs",
      setParentBreadcrumbs: "setParentBreadcrumbs",
      moveRecord: "moveRecord",
      moveMeetingContents: "moveMeetingContents",
      moveBackupMaterial: "moveBackupMaterial",
      copyRecord: "copyRecord",
      moveRecordShortcut: "moveRecordShortcut",
      copyRecordShortcut: "copyRecordShortcut",
      deleteRecord: "deleteRecord",
      referAgendaItem: "referAgendaItem",
      deferAgendaItem: "deferAgendaItem",
      uploadFile: "uploadFile",
      checkInFile: "checkInFile",
      pasteRecordShortcut: "createShortcut",
      deleteRecordShortcut: "deleteRecordShortcut",
      setSearchedChildRecords: "setSearchedChildRecords",
      submitMeetingAgendaItems: "submitMeetingAgendaItems",
      submitSectionAgendaItems: "submitSectionAgendaItems",
      recallMeetingAgendaItems: "recallMeetingAgendaItems",
      recallSectionAgendaItems: "recallSectionAgendaItems"
    }),

    /**
     * Overwrite moduleItemsDrawerMixin Mutations
     */
    ...mapMutations({
      setDrawerMini: SET_DRAWER_MINI,
      setMovedRecord: SET_MOVED_RECORD,
      setMovedRecords: SET_MOVED_RECORDS,
      setLocalUploadedFile: SET_LOCAL_UPLOADED_FILE,
      setRecords: SET_RECORDS,
      appendRecords: APPEND_RECORDS,
      setRecordDeleteStatus: SET_RECORD_DELETE_STATUS,
      updateRecordListById: UPDATE_RECORD_LIST_BY_ID
    }),

    /**
     * Get Stored Selected Module Item id (Record id)
     * Overwrites moduleItemsDrawerMixin.getStoredSelectedModuleItemId
     * @returns {Number|number}
     */
    getStoredSelectedModuleItemId: () => getStoredSelectedModuleItemId(),

    /**
     * get Stored Selected Module Expanded id
     * @return {Number|number}
     */
    getStoredSelectedModuleExpandedId: () =>
      getStoredSelectedModuleExpandedId(),

    /**
     * list (child record) Items
     * @param {Number|number} id
     * @return {Promise<void>}
     */
    async listItems(id) {
      try {
        this.clearError();
        this.isLoading = true;
        const payload = {
          id: id,
          recordIndex: this.startIndex ?? 1,
          count: this.recordsFetchCount ?? 50
        };
        await this.setChildRecords(payload);

        /**
         * append already selected Record to folder Drawer List
         * This makes the record visible/selected even when not fetched
         */
        if (
          this.record?.parentId ===
          Number(this.$router?.currentRoute?.params?.expandedId ?? -2) //Condition makes sure only child is appended to list
        )
          this.appendRecords([this.record]);
      } catch (e) {
        this.handleError(e);
      } finally {
        this.isLoading = false;
        this.loading = false;
      }
    },

    /**
     * Event on Search Action
     * @return {Promise<void>}
     */
    async onSearchAction() {
      try {
        this.clearError();
        console.log(
          `Searched Record is ${this.searchDrawerItemName}`,
          this.defaultSelectModuleItemId
        );

        if (this.searchDrawerItemName) {
          this.isLoading = true;
          this.serverSearchPerformed = true;
          this.isLoading = true;
          const payload = this.buildSearchPayload(
            this.defaultSelectModuleItemId,
            1,
            true
          );
          payload.Name.Value = this.searchDrawerItemName;
          payload.Name.Operator = "*";
          await this.setSearchedChildRecords(payload);
        }
      } catch (e) {
        this.handleError(e);
      } finally {
        this.isLoading = false;
        this.loading = false;
      }
    }
  }
};
