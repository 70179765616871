// model
import { fieldTypes } from "@/model/field/fieldModel";

// design
import { iconAdd } from "@/design/icon/iconConst";

// mixins
import { userMixin } from "@/mixins/shared/user/userMixin";

export const moreFieldsMixin = {
  mixins: [userMixin],
  data() {
    return {
      iconAdd: iconAdd,
      visibleMoreFields: false,
      addMoreFieldsError: undefined
    };
  },
  computed: {
    /**
     * Get available more user's fields
     * @return {{id: number, name: string, children: {id:number, name:string}}[]}
     */
    moreFields() {
      const availableFields = [];

      if (this.countUserFields === 0) {
        return availableFields;
      }

      const validFields = this.userFields?.filter(el =>
        this.isValidMoreField(el.id)
      );

      fieldTypes.forEach(ft => {
        const fields = validFields?.filter(
          field => field.fieldDataType === ft.id
        );

        if ((fields?.length ?? 0) > 0) {
          availableFields.push({
            id: ft.id === 0 ? -100 : -ft.id,
            name: ft.label,
            children: fields.map(f => {
              return {
                id: f.id,
                name: f.name
              };
            })
          });
        }
      });

      return availableFields;
    }
  },
  methods: {
    /**
     * Abstract isValidMoreField
     * @param {number} id
     * @return {boolean}
     */
    isValidMoreField(id) {
      console.warn(`Abstract method isValidMoreField() called. id=${id}`);
      return true;
    },
    /**
     * onSelectMoreFieldsCancel event handler of MoreField
     */
    onSelectMoreFieldsCancel() {
      this.visibleMoreFields = false;
    },
    /**
     * Abstract onSelectMoreFieldsOk
     * @param {number[]} fieldIds
     */
    onSelectMoreFieldsOk(fieldIds) {
      console.warn(
        `Abstract method onSelectMoreFieldsOk() called. fieldIds=${fieldIds}`
      );
    }
  },
  watch: {
    visibleMoreFields() {
      this.addMoreFieldsError = undefined;
    }
  }
};
