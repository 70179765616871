// Using Java Script ECMAScript 2021

// utils
import { isEmpty, itemCount } from "@/utils";

// design
import {
  getSuccessColorClass,
  getSecondaryLightColorClass,
  colorTheme
} from "@/design/colors/Color";
import { fontEmphasis, Text } from "@/design/text/Text";

// model
import {
  defaultSearchOperator,
  defaultSearchRangeToOperator,
  fieldType,
  fieldTypes,
  findFieldType,
  isRangeFromOperator,
  isRangeSearchable,
  rangeToOperators,
  searchOperator,
  searchOperators
} from "@/model/field/fieldModel";
import {
  findRecordState,
  findRecordType,
  findSearchableProperty,
  recordStates,
  recordType,
  recordTypes,
  recordTypeStates,
  searchableProperty
} from "@/model/record/recordModel";
import { fieldName } from "@/model/document/documentModel";
import { headerName } from "@/model/common/dataTable/dataTableConst";

import {
  iconArrowDownDrop,
  iconHint,
  iconWarning
} from "@/design/icon/iconConst";

// services
import { mapHeadersSearchableRecordProps } from "@/model/document/documentModel";
import {
  createQueryFieldModel,
  fieldCriteriaToString,
  formatSearchCriteria,
  hasSearchableValue,
  hasSearchCriteria,
  searchableFieldValue,
  searchableNumericFieldValue
} from "@/services/query/queryService";

// mixins
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import { recordIconMixin } from "@/mixins/shared/record/recordIconMixin";
import { moreFieldsMixin } from "@/mixins/field/moreFieldsMixin";
import { userMixin } from "@/mixins/shared/user/userMixin";

export const searchableMixin = {
  mixins: [menuItemSettingsMixin, recordIconMixin, moreFieldsMixin, userMixin],
  data() {
    return {
      isSearching: false,
      isApplyingSearch: false,
      categorySearchableFields: [],
      recordTypes: recordTypes,
      recordStates: recordStates,

      // Filter
      filterError: undefined,
      filterFieldsCount: 0,
      menuFilterVisible: false,

      // MoreFields
      selectedFlyingFields: [],
      //
      // Icons
      iconArrowDownDrop: iconArrowDownDrop,
      iconHint: iconHint,
      //
      // Searchable Record Properties
      //
      /**
       * @type {{name: string, id: number, label: string, value: undefined, fieldDataType: number, operator: string, include: boolean, lookup: (*|{lookup: {databaseLookup: boolean, enforce: boolean, items: *[]}}), rangeTo: boolean, operators: {description: string, operator: string}[]}}
       */
      fieldRecordId: this.createSearchableField(
        findSearchableProperty(searchableProperty.id)
      ),
      fieldRecordName: this.createSearchableField(
        findSearchableProperty(searchableProperty.name)
      ),
      fieldRecordOwner: this.createSearchableField(
        findSearchableProperty(searchableProperty.owner)
      ),
      fieldRecordCreator: this.createSearchableField(
        findSearchableProperty(searchableProperty.creator)
      ),
      fieldRecordExtension: this.createSearchableField(
        findSearchableProperty(searchableProperty.extension)
      ),
      fieldRecordVersion: this.createSearchableField(
        findSearchableProperty(searchableProperty.version)
      ),
      fieldRecordPages: this.createSearchableField(
        findSearchableProperty(searchableProperty.pages)
      ),
      fieldCreatedFrom: this.createSearchableField(
        findSearchableProperty(searchableProperty.createdDateFrom),
        null,
        false,
        false,
        null
      ),
      fieldCreatedTo: this.createSearchableField(
        findSearchableProperty(searchableProperty.createdDateTo),
        null,
        true,
        false,
        null
      ),
      fieldModifiedFrom: this.createSearchableField(
        findSearchableProperty(searchableProperty.modifiedDateFrom),
        null,
        false,
        false,
        null
      ),
      fieldModifiedTo: this.createSearchableField(
        findSearchableProperty(searchableProperty.modifiedDateTo),
        null,
        true,
        false,
        null
      ),
      fieldRecordType: this.createSearchableField(
        findSearchableProperty(searchableProperty.typeId)
      ),
      fieldRecordState: this.createSearchableField(
        findSearchableProperty(searchableProperty.stateId)
      ),
      /**
       * @type {{id:number, name:string, label:string, fieldDataType:number, operators: {description: string, operator: string}[], value:any, operator:string, include:boolean}}
       */
      fieldRecordCategory: this.createSearchableField(
        findSearchableProperty(searchableProperty.categoryId)
      ),
      fieldChildrenOnly: this.createSearchableField(
        findSearchableProperty(searchableProperty.childrenOnly)
      ),
      //
      // Full-Text Search
      //
      fieldFullText: this.createFullTextField(),
      fieldUniversalSearch: this.createUniversalSearchField(),
      userFieldModel: undefined,
      enableUniversalSearch: true,
      headerColor: colorTheme.warning,
      iconWarning: iconWarning
    };
  },
  computed: {
    /**
     * Search Value for Table
     * @return {*}
     */
    searchValue() {
      return this.enableUniversalSearch
        ? undefined
        : this.fieldUniversalSearch.value;
    },
    /**
     * Selected Category
     * Abstract computed
     * @return {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string, fields: {id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}[]}}
     */
    selectedCategory() {
      return undefined;
    },
    /**
     * Get selected Category Fields
     * @return {{id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags: number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}[]|*[]}
     */
    categoryFields() {
      return this.selectedCategory?.fields ?? [];
    },
    /**
     * Selected Record Category id
     * @return {*|number}
     */
    selectedRecordCategoryId() {
      return this.fieldRecordCategory?.value ?? -1;
    },
    /**
     * Get selected Record Type Icon
     * @return {string}
     */
    selectedRecordTypeIcon() {
      return this.recordTypeIcon(
        this.fieldRecordType?.value ?? recordType.RECORD
      );
    },
    /**
     * Record Properties
     * @return {Array <{name:string, field:{id:number, name:string, label:string, fieldDataType:number, operators: {description: string, operator: string}[], value:any, operator:string, include:boolean}}>}
     */
    recordProps() {
      return [
        { name: searchableProperty.id, field: this.fieldRecordId },
        { name: searchableProperty.name, field: this.fieldRecordName },
        { name: searchableProperty.owner, field: this.fieldRecordOwner },
        { name: searchableProperty.creator, field: this.fieldRecordCreator },
        {
          name: searchableProperty.createdDateFrom,
          field: this.fieldCreatedFrom
        },
        { name: searchableProperty.createdDateTo, field: this.fieldCreatedTo },
        {
          name: searchableProperty.modifiedDateFrom,
          field: this.fieldModifiedFrom
        },
        {
          name: searchableProperty.modifiedDateTo,
          field: this.fieldModifiedTo
        },
        { name: searchableProperty.typeId, field: this.fieldRecordType },
        { name: searchableProperty.stateId, field: this.fieldRecordState },
        {
          name: searchableProperty.extension,
          field: this.fieldRecordExtension
        },
        { name: searchableProperty.version, field: this.fieldRecordVersion },
        { name: searchableProperty.pages, field: this.fieldRecordPages },
        {
          name: searchableProperty.categoryId,
          field: this.fieldRecordCategory
        },
        { name: searchableProperty.childrenOnly, field: this.fieldChildrenOnly }
      ];
    },
    /**
     * Mapping Header Names and Record Property Fields
     * @return {{name:string, field: {id:number, name:string, label:string, fieldDataType:number, operators: {description: string, operator: string}[], value:any, operator:string, include:boolean}} []}
     */
    mappingHeaderNames() {
      return [
        { name: fieldName.recordType, field: this.fieldRecordType },
        { name: fieldName.id, field: this.fieldRecordId },
        { name: fieldName.owner, field: this.fieldRecordOwner },
        { name: fieldName.createdBy, field: this.fieldRecordCreator },
        { name: fieldName.creationDate, field: this.fieldCreatedFrom },
        { name: fieldName.modificationDate, field: this.fieldModifiedFrom },
        { name: fieldName.state, field: this.fieldRecordState },
        { name: fieldName.pageCount, field: this.fieldRecordPages },
        { name: fieldName.extension, field: this.fieldRecordExtension },
        { name: fieldName.version, field: this.fieldRecordVersion }
      ];
    },
    /**
     * Compute searchable Record Properties
     * @return {{name: string, include: boolean, operator: string, value: string}[]}
     */
    searchableRecordProperties() {
      const props = [];

      console.log(
        `searchableRecordProperties() this.recordProps:`,
        this.recordProps
      );

      this.recordProps?.forEach(rp => {
        if (rp.field.include || hasSearchableValue(rp.field)) {
          props.push({
            name: rp.name,
            include: rp.field.include,
            operator: rp.field.operator,
            value: rp.field.value
          });
        }
      });

      // Global Search
      if (this.fieldUniversalSearch.value) {
        props.push({
          name: this.fieldUniversalSearch.name,
          include: this.fieldUniversalSearch.include,
          operator: this.fieldUniversalSearch.operator,
          value: this.fieldUniversalSearch.value
        });
      }

      return props;
    },
    searchableRecordFields() {
      const searchableFields = [];
      const fields = this.searchableRecordFieldsCriteria || [];

      // Save Category Form Fields
      fields?.forEach(el => {
        if (!searchableFields.find(sf => sf.Id === el.Id)) {
          const rangeFields = fields.filter(rf => rf.Id === el.Id) || [];
          const isRange =
            rangeFields.length > 0 &&
            isRangeFromOperator(rangeFields[0].Operator);

          if (isRange) {
            rangeFields.forEach(rf => {
              searchableFields.push(rf);
            });
          } else {
            if (rangeFields.length > 0) {
              searchableFields.push(rangeFields[0]);
            }
          }
        }
      });

      // Save flying Fields
      this.selectedFlyingFields.forEach(el => {
        if (!searchableFields.find(sf => sf.Id === el.id)) {
          const rangeFields =
            this.selectedFlyingFields.filter(rf => rf.id === el.id) || [];
          const isRange =
            rangeFields.length > 0 &&
            isRangeFromOperator(rangeFields[0].operator);

          if (isRange) {
            rangeFields.forEach(rf => {
              searchableFields.push({
                Id: rf.id,
                Include: rf.include,
                Value: rf.value,
                Operator: rf.operator
              });
            });
          } else {
            if (rangeFields.length > 0) {
              searchableFields.push({
                Id: rangeFields[0].id,
                Include: rangeFields[0].include,
                Value: rangeFields[0].value,
                Operator: rangeFields[0].operator
              });
            }
          }
        }
      });

      return searchableFields;
    },
    visibleSearchFileProperties() {
      return this.fieldRecordType.value === recordType.FILE;
    },
    searchableRecordTypes() {
      return this.recordTypes;
    },
    /**
     * Get Searchable Record States
     * @return {unknown[]}
     */
    searchableRecordStates() {
      const recordTypeId = searchableNumericFieldValue(
        this.fieldRecordType?.value
      );

      return recordTypeId >= 0
        ? recordTypeStates(recordTypeId)
        : this.recordStates;
    },
    /**
     * Get record Category Search Criteria
     * @return {{Value:number, Operator:string}}
     */
    recordCategorySearchCriteria() {
      return {
        Operator: searchOperators[searchOperator.equal].operator || "",
        Value: this.selectedCategory?.id ?? -1
      };
    },
    /**
     * Compute Includable Record Fields
     * @return {string[]}
     */
    includableRecordFields() {
      const fields = [];

      // Record Category Fields
      const categoryFields =
        this.categorySearchableFields?.filter(el => el.include) ?? [];

      categoryFields.forEach(field => {
        fields.push(field.id);
      });

      // Flying (additional to category's fields) Fields
      const flyingFields =
        this.selectedFlyingFields?.filter(el => el.include) ?? [];

      flyingFields.forEach(field => {
        fields.push(field.id);
      });

      return fields;
    },
    /**
     * Compute searchable Record Fields Criteria
     * @return {{Id:number, Include:boolean, Value:any, Operator:string}[]}
     */
    searchableRecordFieldsCriteria() {
      /**
       * Get Query Field Models
       * @type {{Id:number, Include:boolean, Operator:string, Value:any }[]}
       */
      const fields = [];

      // Full Text
      if (this.fieldFullText.value) {
        const fieldModel = createQueryFieldModel(
          this.fieldFullText.id,
          this.fieldFullText.include,
          this.fieldFullText.operator,
          this.fieldFullText.value
        );
        fields.push(fieldModel);
      }

      // Category Form Fields
      const searchCategoryFields =
        this.categorySearchableFields.filter(el => el.value || el.include) ||
        [];

      searchCategoryFields.forEach(field => {
        const fieldModel = createQueryFieldModel(
          field.id,
          field.include,
          field.operator,
          field.value
        );
        fields.push(fieldModel);
      });

      // Additional 'flying' Fields
      const searchFlyingFields =
        this.selectedFlyingFields?.filter(el => el.value || el.include) ?? [];

      searchFlyingFields.forEach(field => {
        const fieldModel = createQueryFieldModel(
          field.id,
          field.include,
          field.operator,
          field.value
        );
        fields.push(fieldModel);
      });

      return fields;
    },
    showFilterError: {
      get() {
        return !isEmpty(this.filterError);
      },
      set(value) {
        if (!value) {
          this.filterError = "";
        }
      }
    },
    dismissibleFilterError() {
      return true;
    },

    /**
     * has Search Filter
     * @return {boolean} returns true if Search Filter Criteria Count is bigger then 0
     */
    hasFilter() {
      return this.searchCriteriaCount > 0;
    },

    /**
     * Search filter Tooltip
     * @return {string} returns a useful user-friendly Search filter tooltip
     */
    filterTooltip() {
      return `Search Filter Count: ${this.searchCriteriaCount}`;
    },

    /**
     * Is visible Filter Badge
     * @return {boolean} returns true if Filter Badge is visible, else returns false
     */
    visibleFilterBadge() {
      return true;
    },

    /**
     * filter Badge Message
     * @return {string} returns formatted filter Badge Message
     */
    filterBadgeMessage() {
      return `${this.searchCriteriaCount}`;
    },

    /**
     * filter Badge Color class
     * @return {string|*} returns filter Badge Color class
     * based on search Criteria Count
     */
    filterBadgeColorClass() {
      return this.searchCriteriaCount > 0
        ? getSuccessColorClass()
        : getSecondaryLightColorClass();
    },
    filterSearchableRecordProps() {
      return this.recordProps?.filter(
        rp =>
          rp?.field?.name !== searchableProperty.childrenOnly &&
          hasSearchableValue(rp.field)
      );
    },
    hasSearchableRecordProps() {
      return (this?.filterSearchableRecordProps?.length ?? 0) > 0;
    },

    /**
     * Used for Search Filter UI - SearchableRecordProps
     * @return {unknown[] | undefined}
     */
    filterAdvancedSearchableRecordProps() {
      return this.recordProps?.filter(
        rp =>
          rp?.field?.name !== searchableProperty.childrenOnly &&
          rp?.field?.name !== searchableProperty.categoryId &&
          rp?.field?.name !== searchableProperty.name &&
          hasSearchableValue(rp.field)
      );
    },

    // Used for Search Filter UI - SearchableRecordProps
    hasAdvancedSearchableRecordProps() {
      return (this?.filterAdvancedSearchableRecordProps?.length ?? 0) > 0;
    },
    hasFullTextCriteria() {
      return !isEmpty(this.fieldFullText.value);
    },
    hasUniversalSearchCriteria() {
      return !isEmpty(this.fieldUniversalSearch.value);
    },
    filterSearchableRecordCategoryFields() {
      return (
        this.categorySearchableFields?.filter(fld => hasSearchableValue(fld)) ??
        []
      );
    },
    hasSearchableRecordCategoryFields() {
      return (this.filterSearchableRecordCategoryFields?.length ?? 0) > 0;
    },

    /**
     * Used for Search Filter UI - RecordCategoryFields
     * @return {*[]}
     */
    filterAdvancedSearchableRecordCategoryFields() {
      const searchableRecordProps = this.recordProps
        ?.filter(
          rp =>
            rp?.field?.name === searchableProperty.categoryId &&
            hasSearchableValue(rp.field)
        )
        .map(rp => rp.field);
      return [
        ...this.filterSearchableRecordCategoryFields,
        ...searchableRecordProps
      ];
    },

    //Used for Search Filter UI - RecordCategoryFields
    hasAdvancedSearchableRecordCategoryFields() {
      return (
        (this.filterAdvancedSearchableRecordCategoryFields?.length ?? 0) > 0
      );
    },
    filterSearchableFlyingFields() {
      return this.selectedFlyingFields?.filter(fld => hasSearchableValue(fld));
    },
    hasSearchableFlyingFields() {
      return (this?.filterSearchableFlyingFields?.length ?? 0) > 0;
    },

    filterHeaderRecord: () => `Record Properties`,
    filterHeaderFullText: () => `Full Text`,
    filterHeaderUniversalSearch: () => `Search`,
    filterHeaderCategoryFields: () => `Category`,
    filterHeaderMoreFields: () => `More Fields`,
    commandSearchLabel: () => `OK`,
    commandApplyLabel: () => `Apply`,
    commandSearchReset: () => `Clear`,
    commandSearchCancelLabel: () => "Cancel",
    commandCloseSearchDialogTooltip: () => "Close Search Dialog",
    commandBrowseTooltip: () => "Browse Search Template",
    commandSaveTooltip: () => "Save Search Template",
    commandResetTooltip: () => "Clear Search Filter",
    commandSearchLabelTooltip: () => "Apply Search Filter and Close",
    commandApplyLabelTooltip: () => "Apply Search Filter",
    filterHeaderMore: () => `More`,

    /**
     * Compute if Performing Search;
     * @return {boolean}
     */
    isPerformingSearch() {
      return this.isSearching || this.isApplyingSearch;
    },

    /**
     * Compute Search Criteria Count
     * @return {number|number}
     */
    searchCriteriaCount() {
      return this.searchCriteriaList?.length ?? 0;
    },

    /**
     * Can Search within a record
     * @return {boolean} returns whether search can be performed within a record
     */
    canSearchWithinRecord() {
      return false;
    },

    /**
     * search Criteria To String Array
     * @return {{string}[]} String Array of search criteria
     */
    searchCriteriaList() {
      const criteria = [];

      // child Records
      if (this.canSearchWithinRecord) {
        // if (this.childRecordsCriteriaToString) {
        //   criteria.push(this.childRecordsCriteriaToString());
        // }
      }

      // Records Properties
      if (this.hasSearchableRecordProps) {
        this.filterSearchableRecordProps.forEach(srp => {
          criteria.push({
            name: srp.name,
            value: this.recordPropCriteriaToString(srp)
          });
        });
      }

      // Full Text
      if (this.hasFullTextCriteria) {
        criteria.push({
          name: this.fieldFullText.name,
          value: this.fullTextCriteriaToString()
        });
      }

      console.log(
        `hasSearchableRecordCategoryFields:`,
        this.hasSearchableRecordCategoryFields
      );
      console.log(
        `filterSearchableRecordCategoryFields:`,
        this.filterSearchableRecordCategoryFields
      );

      // Records Category Fields
      if (this.hasSearchableRecordCategoryFields) {
        this.filterSearchableRecordCategoryFields.forEach(field => {
          criteria.push({
            name: field.name,
            value: this.fieldCriteriaToString(field)
          });
        });
      }

      // Flying Fields
      if (this.hasSearchableFlyingFields) {
        this.filterSearchableFlyingFields.forEach(field => {
          criteria.push({
            name: field.name,
            value: this.fieldCriteriaToString(field)
          });
        });
      }

      //Universal/Global Search
      if (this.enableUniversalSearch) {
        if (this.hasUniversalSearchCriteria) {
          criteria.push({
            name: this.fieldUniversalSearch.name,
            value: this.universalSearchCriteriaToString()
          });
        }
      }
      return criteria;
    },

    /**
     * search Criteria To String Array for More (expansion panel) in Search Filter
     * @return {{string}[]} String Array of search criteria
     */
    advancedCriteriaList() {
      const advancedCriteria = [...this.searchCriteriaList];

      const indexToRemove = advancedCriteria.findIndex(
        criteria => criteria.name === this.fieldUniversalSearch.name
      );

      // If the object was found, remove it
      if (indexToRemove !== -1) {
        advancedCriteria.splice(indexToRemove, 1);
      }

      return advancedCriteria;
    },

    /**
     * Build and Get Disabled Universal Search Reason
     * @return {string}
     */
    getDisabledSearchReason() {
      const reasonBuilder = [];

      if (!this.enableUniversalSearch) {
        reasonBuilder.push("Search Anything Disabled because ");

        this.filterSearchableRecordProps.forEach(srp => {
          if (srp.name === searchableProperty.name) {
            reasonBuilder.push(`Record Name: ${srp.field.value}`);
          }

          if (srp.name === searchableProperty.createdDateTo) {
            reasonBuilder.push(`Created Date : ${srp.field.value}`);
          }

          if (srp.name === searchableProperty.createdDateFrom) {
            reasonBuilder.push(`Created Date : ${srp.field.value}`);
          }
        });

        if (this.hasFullTextCriteria) {
          reasonBuilder.push(`, OCR Text: ${this.fieldFullText.value}`);
        }

        this.filterSearchableRecordCategoryFields.forEach(field => {
          reasonBuilder.push(`, ${field.name}: ${field.value}`);
        });

        this.filterSearchableFlyingFields.forEach(field => {
          reasonBuilder.push(`, ${field.name}: ${field.value}`);
        });

        reasonBuilder.push(" Is Provided.");
      }

      return reasonBuilder.join("");
    }
  },
  methods: {
    /**
     * overwrite  moreFieldsMixin.isValidMoreField
     * @param {number} id
     * @return {boolean}
     */
    isValidMoreField(id) {
      return (
        id > 0 &&
        this.isAllowedViewField(id) &&
        this.isFlyingField(id) &&
        !this.selectedFlyingFields?.find(sf => sf.id === id)
      );
    },
    countSearchableFilterProps(props) {
      if (itemCount(props) === 0) {
        return 0;
      }

      return props.filter(p => !!(p.value?.toString() ?? undefined)).length;
    },
    countSearchableFilterFields(fields) {
      if (itemCount(fields) === 0) {
        return 0;
      }

      return fields.filter(f => hasSearchableValue(f))?.length ?? 0;
    },
    /**
     * Abstract method: Set Search Category to be implemented by client
     * @param {number}id
     */
    setSearchCategory(id) {
      console.warn(`Not implemented setSearchCategory(id) (id: ${id})`);
    },

    resetSearchableRecordProperties() {
      try {
        this.fieldRecordId = this.createSearchableField(
          findSearchableProperty(searchableProperty.id)
        );

        this.fieldRecordName = this.createSearchableField(
          findSearchableProperty(searchableProperty.name)
        );
        this.fieldRecordOwner = this.createSearchableField(
          findSearchableProperty(searchableProperty.owner)
        );
        this.fieldRecordCreator = this.createSearchableField(
          findSearchableProperty(searchableProperty.creator)
        );
        this.fieldRecordExtension = this.createSearchableField(
          findSearchableProperty(searchableProperty.extension)
        );
        this.fieldRecordVersion = this.createSearchableField(
          findSearchableProperty(searchableProperty.version)
        );
        this.fieldRecordPages = this.createSearchableField(
          findSearchableProperty(searchableProperty.pages)
        );

        this.fieldCreatedFrom = this.createSearchableField(
          findSearchableProperty(searchableProperty.createdDateFrom),
          null,
          false,
          false,
          null
        );

        this.fieldCreatedTo = this.createSearchableField(
          findSearchableProperty(searchableProperty.createdDateTo),
          null,
          true,
          false,
          null
        );
        this.fieldModifiedFrom = this.createSearchableField(
          findSearchableProperty(searchableProperty.modifiedDateFrom),
          null,
          false,
          false,
          null
        );
        this.fieldModifiedTo = this.createSearchableField(
          findSearchableProperty(searchableProperty.modifiedDateTo),
          null,
          true,
          false,
          null
        );
        this.fieldRecordType = this.createSearchableField(
          findSearchableProperty(searchableProperty.typeId)
        );
        this.fieldRecordState = this.createSearchableField(
          findSearchableProperty(searchableProperty.stateId)
        );
        /**
         * @type {{id:number, name:string, label:string, fieldDataType:number, operators: {description: string, operator: string}[], value:any, operator:string, include:boolean}}
         */
        this.fieldRecordCategory = this.createSearchableField(
          findSearchableProperty(searchableProperty.categoryId)
        );
        this.fieldChildrenOnly = this.createSearchableField(
          findSearchableProperty(searchableProperty.childrenOnly)
        );
        //
        // Full-Text Search
        //
        this.fieldFullText = this.createFullTextField();

        //
        // Global Field
        //
        this.fieldUniversalSearch = this.createUniversalSearchField();
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Load Search Record Properties
     * @param {{name: string, include: boolean, operator: string, value: string}[]} props
     */
    loadSearchRecordProps(props) {
      if (!(itemCount(props) > 0 && itemCount(this.recordProps) > 0)) {
        return;
      }

      this.recordProps.forEach(rp => {
        const prop = props.find(p => p.name === rp.name);

        if (prop) {
          const op = rp.field.operators.find(
            op => op.operator === prop.operator
          );
          rp.field.operator = op
            ? op.operator
            : defaultSearchOperator(rp.field.fieldDataType);
        } else {
          rp.field.operator = defaultSearchOperator(rp.field.fieldDataType);
        }

        rp.field.include = prop?.include ?? false;
        rp.field.value = prop?.value ?? null;
      });

      // Global Search
      const globalSearchProp = props.find(
        p => p.name === this.fieldUniversalSearch?.name
      );
      if (globalSearchProp) {
        this.fieldUniversalSearch.value = globalSearchProp?.value ?? "";
      }
    },
    /**
     * default Searchable Record Properties
     * @param {{text: string, value: string, visible: boolean}[]} defaultHeaders
     */
    defaultSearchableRecordProps(defaultHeaders) {
      const props = [];

      const headers = defaultHeaders.filter(
        h => !!h.visible && h.value !== headerName.actions
      );

      headers.forEach(vh => {
        const hrp = mapHeadersSearchableRecordProps.find(
          hrp => hrp.headerValue === vh.value
        );
        if (hrp) {
          const sp = findSearchableProperty(hrp.propName);
          if (sp) {
            const prop = {
              name: sp.name,
              include: vh.visible,
              operator: sp.searchOperators[0].operator,
              value: ""
            };
            props.push(prop);
          } else {
            console.warn(
              `Couldn't find Searchable Property by Property Name: ${hrp.propName}`
            );
          }
        } else {
          console.warn(
            `Couldn't find header by Searchable Record Property: ${vh.value}`
          );
        }
      });

      return props;
    },
    /**
     * lLad Search Record Fields
     * @param {{Id:number, Include:boolean, Value:any, Operator:string}[]} fields
     */
    loadSearchRecordFields(fields) {
      // Clear relevant fields
      this.categorySearchableFields = [];
      this.selectedFlyingFields = [];
      this.fieldFullText.value = "";

      console.log(
        `${this.$options.name}.loadSearchRecordFields() fields:`,
        fields
      );

      // Full Text
      const fullText = fields?.find(el => el.Id === 0) ?? "";
      this.fieldFullText.value = fullText?.Value ?? "";

      // Set Category Searchable Fields
      this.categoryFields.forEach(field => {
        const fieldsById = fields
          ? fields.filter(el => el.Id === field.id)
          : null;
        const fieldFrom = (fieldsById?.length ?? 0) >= 1 ? fieldsById[0] : null;

        this.categorySearchableFields.push(
          this.createSearchableField(
            field,
            fieldFrom?.Value ?? null,
            false,
            fieldFrom?.Include ?? false,
            fieldFrom?.Operator ?? defaultSearchOperator(field.fieldDataType)
          )
        );

        if (this.isFieldRangeSearchable(field)) {
          const fieldTo =
            fieldsById && fieldsById.length >= 2 ? fieldsById[1] : null;

          this.categorySearchableFields.push(
            this.createSearchableField(
              field,
              fieldTo?.Value ?? null,
              true,
              fieldTo?.Include ?? false,
              fieldTo?.Operator ?? defaultSearchRangeToOperator
            )
          );
        }
      });

      //
      // Set Flying Searchable Fields
      //
      const flyingFields =
        fields?.filter(el => this.isFlyingField(el.Id)) ?? [];

      flyingFields.forEach(sff => {
        const userField = this.findUserField(sff.Id);
        const isAddFlyingField =
          userField && !this.existsSelectedFlyingField(sff.Id);

        if (isAddFlyingField) {
          const fields = flyingFields?.filter(sffEl => sffEl.Id === sff.Id);
          const fieldFrom = (fields?.length ?? 0) >= 1 ? fields[0] : null;

          const flyingField = {
            id: userField.id,
            name: userField.name,
            label: userField.name,
            fieldDataType: userField.fieldDataType,
            searchOperators: this.getSearchOperators(userField.fieldDataType)
          };

          this.selectedFlyingFields.push(
            this.createSearchableField(
              flyingField,
              fieldFrom?.Value ?? null,
              false,
              fieldFrom?.Include ?? false,
              fieldFrom?.Operator ??
                defaultSearchOperator(userField.fieldDataType)
            )
          );

          if (this.isFieldRangeSearchable(flyingField)) {
            const fieldTo = (fields?.length ?? 0) > 0 >= 2 ? fields[1] : null;

            const flyingFieldTo = this.createSearchableField(
              flyingField,
              fieldTo?.Value ?? null,
              true,
              fieldTo?.Include ?? false,
              fieldTo?.Operator ?? defaultSearchRangeToOperator
            );

            this.selectedFlyingFields.push(flyingFieldTo);
          }
        }
      });
    },
    /**
     * Get Record Property Search Criteria
     * @param {string} propId
     * @return {{Operator:string, Value:any}} propId
     */
    recordPropertySearchCriteria(propId) {
      const prop = this?.recordProps?.find(rp => rp.name === propId);

      if (!prop) {
        console.warn(`Cannot find propId:`, propId);
        return {
          Value: null,
          Operator: searchOperators[searchOperator.equal].operator
        };
      }

      return {
        Operator: prop.field.operator,
        Value: searchableFieldValue(prop.field)
      };
    },

    /**
     * Create Searchable Field
     * @param {{id:number, name:string, label:string, fieldDataType:number, searchOperators: {description: string, operator: string}[]}} field
     * @param {any} fieldValue
     * @param {boolean} rangeTo
     * @param {boolean} include
     * @param operator
     * @return {{include: boolean, lookup: (*|{lookup: {databaseLookup: boolean, enforce: boolean, items: *[]}}), rangeTo: boolean, operators: {description: string, operator: string}[], name: string, id: number, label: string, value: undefined, fieldDataType: number, operator: string|*}}
     */
    createSearchableField(
      field,
      fieldValue = undefined,
      rangeTo = false,
      include = false,
      operator = null
    ) {
      let selectOperator = operator;
      if (!selectOperator) {
        if (rangeTo) {
          selectOperator = defaultSearchRangeToOperator;
        } else {
          if ((field?.searchOperators?.length ?? 0) > 0) {
            selectOperator =
              field.name === searchableProperty.name
                ? field.searchOperators[1].operator
                : field.searchOperators[0].operator;
          }
        }
      }

      if (!selectOperator) {
        console.warn(`Not found default Operator:`, selectOperator);
      }

      // console.log("createSearchableField() field:", field);
      // console.log("selectOperator:", selectOperator);

      const lookup = field?.lookup ?? {
        lookup: {
          databaseLookup: false,
          enforce: false,
          items: []
        }
      };

      let foundOperator = field?.searchOperators?.find(
        el => el?.operator === selectOperator
      );

      if (!foundOperator) {
        console.warn(`NOT VALID default Operator:`, selectOperator);
      }

      return {
        id: field.id,
        name: field.name,
        label: field.label,
        fieldDataType: field.fieldDataType,
        operators: rangeTo ? rangeToOperators : field.searchOperators,
        value: fieldValue,
        operator: selectOperator,
        rangeTo: rangeTo,
        include: include,
        lookup: lookup
      };
    },
    createFullTextField() {
      const field = {
        id: 0,
        name: "FullText",
        label: "OCR Text",
        fieldDataType: fieldType.TEXT,
        searchOperators: findFieldType(fieldType.TEXT)?.searchOperators ?? []
      };

      return this.createSearchableField(field);
    },

    createUniversalSearchField() {
      const field = {
        id: 1,
        name: "Global Search",
        label: "Search Anything",
        fieldDataType: fieldType.TEXT,
        searchOperators: findFieldType(fieldType.TEXT)?.searchOperators ?? []
      };

      return this.createSearchableField(field);
    },

    /**
     * get default Search Operator
     * @param {{id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}} field
     * @return {string}
     */
    defaultSearchOperator(field) {
      return defaultSearchOperator(field.fieldDataType);
    },
    isFieldRangeSearchable(field) {
      return isRangeSearchable(field?.fieldDataType ?? -1);
    },
    isFlyingField(id) {
      if (itemCount(this.categorySearchableFields === 0)) {
        return false;
      }

      // OCR Text field id = 0
      if (id === 0) {
        return false;
      }

      return !this.categorySearchableFields.find(el => el.id === id);
    },
    getRangeToField(fields, field) {
      if (!fields || !field) return null;

      return fields.find(el => el.id === field.id && el.rangeTo);
    },
    getSearchOperators(fieldTypeId) {
      const ft = fieldTypes.find(el => el.id === fieldTypeId);

      return ft ? ft.searchOperators : [];
    },
    existsSelectedFlyingField(id) {
      return this.selectedFlyingFields?.find(el => el.id === id) ?? false;
    },
    onRemoveFlyingField(field) {
      try {
        const fields = this.selectedFlyingFields
          ? this.selectedFlyingFields.filter(el => el.id === field.id)
          : [];

        fields.forEach(el => {
          const index = this.selectedFlyingFields.indexOf(el);

          if (index >= 0) {
            this.selectedFlyingFields.splice(index, 1);
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    /**
     * onSelectMoreFieldsOk
     * @param {number[]} fieldIds
     */
    async onSelectMoreFieldsOk(fieldIds) {
      try {
        this.addMoreFieldsError = undefined;

        console.log(`onSelectMoreFieldsOk() fieldIds:`, fieldIds);

        const ids =
          fieldIds?.filter(f => !this.existsSelectedFlyingField(f)) ?? [];
        const count = ids?.length ?? 0;
        if (count === 0) {
          return;
        }

        for (const id of ids) {
          const field = this.findUserField(id);
          if (field) {
            await this.addFlyingField(field);
          }
        }

        this.visibleMoreFields = false;
      } catch (e) {
        console.error(e);
        this.addMoreFieldsError = e.toString();
      }
    },

    /**
     * add Flying Field
     * @param {{id:number, name:string, fieldDataType:number, fieldDataTypeName:string, flags:number, immutable:boolean, unselectable:boolean, isPersistentField:boolean, isSystemCategoryType:boolean, isSystemField:boolean, unselectable:boolean, operations: {name:string, allowed:boolean, valid:boolean}}} field
     * @return {Promise<void>}
     */
    async addFlyingField(field) {
      if (!field) {
        throw "Couldn't add field. Field not provided";
      }

      // console.log(`addFlyingField() field:`, field);

      let formField = this.findFormField(field.id);
      if (!formField) {
        await this.setFormField(field.id);
        formField = this.findFormField(field.id);
      }

      // console.log(`addFlyingField() formField:`, formField);

      const flyingField = {
        id: field.id,
        name: field.name,
        label: field.name,
        fieldDataType: field.fieldDataType,
        searchOperators: this.getSearchOperators(field.fieldDataType),
        lookup: {
          databaseLookup: formField?.lookup?.databaseLookup ?? false,
          enforce: formField?.lookup?.enforce ?? false,
          items: formField?.lookup?.items ?? []
        }
      };

      // console.log(`addFlyingField() formField:`, flyingField);

      this.selectedFlyingFields.push(
        this.createSearchableField(
          flyingField,
          null,
          false,
          false,
          defaultSearchOperator(field.fieldDataType)
        )
      );

      if (this.isFieldRangeSearchable(flyingField)) {
        this.selectedFlyingFields.push(
          this.createSearchableField(
            flyingField,
            null,
            true,
            false,
            defaultSearchRangeToOperator
          )
        );
      }
    },

    /**
     * Build Search Payload
     * @param {number} withInId
     * @param {number} pageNumber
     * @param {Boolean} isCategoryModule
     * @return {{CategoryId: ({Value: number, Operator: string}|{Operator: string, Value: *}), TypeId: {Operator: string, Value: *}, Owner: {Operator: string, Value: *}, CreatedDateTo: {Operator: string, Value: *}, WithInId: number, ChildrenOnly: boolean, StateId: {Operator: string, Value: *}, Creator: {Operator: string, Value: *}, Name: {Operator: string, Value: *}, Extension: {Operator: string, Value: *}, Pages: {Operator: string, Value: *}, Fields: {Id: number, Include: boolean, Value: *, Operator: string}[], StartPage: number, Version: {Operator: string, Value: *}, CreatedDateFrom: {Operator: string, Value: *}, ModifiedDateTo: {Operator: string, Value: *}, Id: {Operator: string, Value: *}[], ModifiedDateFrom: {Operator: string, Value: *}, CountPerPage: ((function(): (Number|number))|*|number)}}
     */
    buildSearchPayload(
      withInId = -1,
      pageNumber = 1,
      isCategoryModule = false
    ) {
      return {
        Id: [this.recordPropertySearchCriteria(searchableProperty.id)],
        Name: this.recordPropertySearchCriteria(searchableProperty.name),
        Owner: this.recordPropertySearchCriteria(searchableProperty.owner),
        Creator: this.recordPropertySearchCriteria(searchableProperty.creator),
        CreatedDateFrom: this.recordPropertySearchCriteria(
          searchableProperty.createdDateFrom
        ),
        CreatedDateTo: this.recordPropertySearchCriteria(
          searchableProperty.createdDateTo
        ),
        ModifiedDateFrom: this.recordPropertySearchCriteria(
          searchableProperty.modifiedDateFrom
        ),
        ModifiedDateTo: this.recordPropertySearchCriteria(
          searchableProperty.modifiedDateTo
        ),
        TypeId: this.recordPropertySearchCriteria(searchableProperty.typeId),
        StateId: this.recordPropertySearchCriteria(searchableProperty.stateId),
        Extension: this.recordPropertySearchCriteria(
          searchableProperty.extension
        ),
        Version: this.recordPropertySearchCriteria(searchableProperty.version),
        Pages: this.recordPropertySearchCriteria(searchableProperty.pages),
        CategoryId: isCategoryModule
          ? this.recordCategorySearchCriteria
          : this.recordPropertySearchCriteria(searchableProperty.categoryId),
        Fields: this.searchableRecordFieldsCriteria,
        WithInId: withInId,
        ChildrenOnly: false,
        // ChildrenOnly: this.recordPropertySearchCriteria(
        //   searchableProperty.childrenOnly
        // ),
        StartPage: pageNumber,
        CountPerPage: this.perPageCount ?? 50 // can be configurable
      };
    },
    hasSearchCriteria(criteria, excludeWithInId = true) {
      return hasSearchCriteria(criteria, excludeWithInId);
    },
    /**
     * Insert Includable Record Fields to dataTable headers
     * @param {{text: string, value: string, visible: boolean}[]} headers
     */
    insertIncludableRecordFields(headers) {
      if (itemCount(headers) === 0) {
        return;
      }

      const fields = this.includableRecordFields;
      if (itemCount(fields) === 0) {
        return;
      }

      let i = 0;
      fields.forEach(id => {
        const index = headers.length - 1;
        const field = this.findUserField(id);
        headers.splice(index, 0, {
          text: field ? field.name : `Field ${id}`,
          value: `searchFields[${i}].value`,
          visible: true
        });
        i++;
      });
    },
    /**
     * Set Record Property Headers Visibility
     * @param {{text: string, value: string, visible: boolean}[]} headers
     */
    setRecordPropertyHeadersVisibility(headers) {
      this.mappingHeaderNames.forEach(el => {
        const header = this.findHeader(headers, el.name);
        if (header) {
          header.visible = el.field.include;
        } else {
          console.warn(`Couldn't find DataTable header by name: ${el.name}`);
        }
      });
    },
    /**
     * Find Header
     * @param {{text: string, value: string, visible: boolean}[]} headers
     * @param {string} value
     * @return {{text: string, value: string, visible: boolean}}
     */
    findHeader(headers, value) {
      return itemCount(headers) > 0
        ? headers.find(el => el.value === value)
        : undefined;
    },
    /**
     * Expansion panel Header Class
     * @param {boolean} strong
     * @return {string}
     */
    panelHeaderClass(strong) {
      return new Text(
        strong ? this.mdMenuItemColor : undefined,
        undefined,
        strong ? fontEmphasis.bold : fontEmphasis.regular
      ).getClassText();
    },
    /**
     * record Prop Criteria To String
     * @param {{name:string, field:{id:number, name:string, label:string, fieldDataType:number, operators: {description: string, operator: string}[], value:any, operator:string, include:boolean}}} prop
     * @return {string}
     */
    recordPropCriteriaToString(prop) {
      if (!prop?.field) return "";

      const label = prop.field.label ?? prop.field.name ?? "";

      // category Id
      if (prop.field.name === searchableProperty.categoryId) {
        const id = searchableNumericFieldValue(prop.field.value ?? -1);
        const category = this.findCategory(id);

        return category
          ? `Category: ${category?.name}`
          : formatSearchCriteria(label, prop.field.operator, id);
      }

      // Record type id
      if (prop.field.name === searchableProperty.typeId) {
        const typeId = searchableNumericFieldValue(prop.field.value ?? -1);
        const rt = findRecordType(typeId);

        return rt
          ? formatSearchCriteria(label, prop.field.operator, rt.name)
          : formatSearchCriteria(label, prop.field.operator, typeId);
      }

      // state Id
      if (prop.field.name === searchableProperty.stateId) {
        const stateId = searchableNumericFieldValue(prop.field.value ?? -1);
        const rs = findRecordState(stateId);

        return rs
          ? formatSearchCriteria(label, prop.field.operator, rs.name)
          : formatSearchCriteria(label, prop.field.operator, stateId);
      }

      return formatSearchCriteria(label, prop.field.operator, prop.field.value);
    },

    /**
     * Get Category Criteria To String - Search Filter UI
     * @param field
     * @return {string}
     */
    categoryCriteriaToString(field) {
      return field.name === searchableProperty.categoryId
        ? this.recordPropCriteriaToString({
            name: searchableProperty.categoryId,
            field: field
          })
        : this.fieldCriteriaToString(field);
    },

    /**
     * Field Criteria To String
     * @param {{id:number, name:string, label:string, fieldDataType:number, operators: {description: string, operator: string}[], value:any, operator:string, include:boolean}} field
     * @return {string}
     */
    fieldCriteriaToString(field) {
      return fieldCriteriaToString(field);
    },

    fullTextCriteriaToString() {
      return `OCR Text: ${this.fieldFullText.value}`;
    },

    universalSearchCriteriaToString() {
      return `Search: ${this.fieldUniversalSearch.value}`;
    },

    /**
     * Fill the search filter with user-defined model
     * Populates Data as well as fields
     * @param model {{CategoryId: {Value: number, Operator: string}, TypeId: {Operator: string, Value: *}, Owner: {Operator: string, Value: *}, CreatedDateTo: {Operator: string, Value: *}, StateId: {Operator: string, Value: *}, Creator: {Operator: string, Value: *}, Name: {Operator: string, Value: *}, Extension: {Operator: string, Value: *}, Pages: {Operator: string, Value: *}, Fields: {Id: number, Include: boolean, Value: *, Operator: string}[], StartPage: number, Version: {Operator: string, Value: *}, CreatedDateFrom: {Operator: string, Value: *}, ModifiedDateTo: {Operator: string, Value: *}, Id: {Operator: string, Value: *}, ModifiedDateFrom: {Operator: string, Value: *}, ChildrenOnly: {Value: number, Operator: string}, WithInId:number, CountPerPage: number, GlobalSearch: Object}}
     */
    populateUserSearchTemplateData(model) {
      this.userFieldModel = undefined;
      try {
        this.polishUserModel(model);

        this.fieldRecordId = this.createSearchableField(
          findSearchableProperty(searchableProperty.id),
          model?.Id?.Value ?? undefined,
          false,
          this.fieldRecordId?.include ?? false,
          model?.Id?.Operator ?? null
        );

        this.fieldRecordName = this.createSearchableField(
          findSearchableProperty(searchableProperty.name),
          model?.Name?.Value ?? undefined,
          false,
          this.fieldRecordName?.include ?? false,
          model?.Name.Operator ?? null
        );

        this.fieldRecordOwner = this.createSearchableField(
          findSearchableProperty(searchableProperty.owner),
          model?.Owner?.Value ?? undefined,
          false,
          this.fieldRecordOwner?.include ?? false,
          model?.Owner.Operator ?? null
        );

        this.fieldRecordCreator = this.createSearchableField(
          findSearchableProperty(searchableProperty.creator),
          model?.Creator?.Value ?? undefined,
          false,
          this.fieldRecordCreator?.include ?? false,
          model?.Creator.Operator ?? null
        );

        this.fieldRecordExtension = this.createSearchableField(
          findSearchableProperty(searchableProperty.extension),
          model?.Extension?.Value ?? undefined,
          false,
          this.fieldRecordExtension?.include ?? false,
          model?.Extension.Operator ?? null
        );

        this.fieldRecordVersion = this.createSearchableField(
          findSearchableProperty(searchableProperty.version),
          model?.Version?.Value ?? undefined,
          false,
          this.fieldRecordVersion?.include ?? false,
          model?.Version.Operator ?? null
        );

        this.fieldRecordPages = this.createSearchableField(
          findSearchableProperty(searchableProperty.pages),
          model?.Pages?.Value ?? undefined,
          false,
          this.fieldRecordPages?.include ?? false,
          model?.Pages.Operator ?? null
        );

        this.fieldCreatedFrom = this.createSearchableField(
          findSearchableProperty(searchableProperty.createdDateFrom),
          model?.CreatedDateFrom?.Value ?? undefined,
          false,
          this.fieldCreatedFrom?.include ?? false,
          model?.CreatedDateFrom?.Operator ?? null
        );

        this.fieldCreatedTo = this.createSearchableField(
          findSearchableProperty(searchableProperty.createdDateTo),
          model?.CreatedDateTo?.Value ?? undefined,
          true,
          this.fieldCreatedTo?.include ?? false,
          model?.CreatedDateTo?.Operator ?? null
        );

        this.fieldModifiedFrom = this.createSearchableField(
          findSearchableProperty(searchableProperty.modifiedDateFrom),
          model?.ModifiedDateFrom?.Value ?? undefined,
          false,
          this.fieldModifiedFrom?.include ?? false,
          model?.ModifiedDateFrom?.Operator ?? null
        );

        this.fieldModifiedTo = this.createSearchableField(
          findSearchableProperty(searchableProperty.modifiedDateTo),
          model?.ModifiedDateTo?.Value ?? undefined,
          true,
          this.fieldModifiedTo?.include ?? false,
          model?.ModifiedDateTo?.Operator ?? null
        );

        this.fieldRecordType = this.createSearchableField(
          findSearchableProperty(searchableProperty.typeId),
          model?.TypeId?.Value ?? undefined,
          false,
          this.fieldRecordType?.include ?? false,
          model?.TypeId?.Operator ?? null
        );

        this.fieldRecordState = this.createSearchableField(
          findSearchableProperty(searchableProperty.stateId),
          model?.StateId?.Value ?? undefined,
          false,
          this.fieldRecordState?.include ?? false,
          model?.StateId?.Operator ?? null
        );

        this.fieldRecordCategory = this.createSearchableField(
          findSearchableProperty(searchableProperty.categoryId),
          model?.CategoryId?.Value ?? undefined,
          false,
          this.fieldRecordCategory?.include ?? false,
          model?.CategoryId?.Operator ?? null
        );

        this.fieldUniversalSearch =
          model?.GlobalSearch ?? this.createUniversalSearchField();

        // TODO: Determine whether this field should be filled when importing search template
        /* this.fieldChildrenOnly = this.createSearchableField(
          findSearchableProperty(searchableProperty.childrenOnly),
          model?.ChildrenOnly?.Value ?? undefined,
          false,
          false,
          model?.ChildrenOnly?.Operator
        );*/

        // This will populate all the form fields from user-defined template
        this.userFieldModel = model.Fields;
        this.loadSearchRecordFields(this.userFieldModel);
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Sets all the unset value to null from default -1
     * -1 looks ugly
     * @param model
     */
    polishUserModel(model) {
      for (let key in model) {
        if (model[key].Value === -1) {
          model[key].Value = null;
        }
      }
    },

    /**
     * Populate Search Toolbar if condition met
     */
    populateSearchField() {
      const nameFieldValue = this.fieldRecordName?.value ?? undefined;
      if (nameFieldValue) {
        this.search =
          this.fieldRecordName?.operator === "*" ? nameFieldValue : this.search;
      }
    },

    /**
     * Method to update enableUniversalSearch based on all conditions
     */
    updateEnableUniversalSearch() {
      // Check all conditions and update enableUniversalSearch accordingly
      this.enableUniversalSearch =
        !this.filterSearchableRecordProps.some(prop => {
          return (
            prop?.name === searchableProperty.name ||
            prop?.name === searchableProperty.createdDateTo ||
            prop?.name === searchableProperty.createdDateFrom
          );
        }) &&
        !this.filterSearchableRecordCategoryFields.length &&
        !this.filterSearchableFlyingFields.length &&
        !this.hasFullTextCriteria;
    },

    /**
     * Adjust Payload to accommodate Universal Search
     * @param payload
     */
    buildUniversalSearchPayload(payload) {
      payload.Name.Operator = "*";
      payload.Name.Value = this.fieldUniversalSearch.value;

      payload.Id.push({
        Operator: "=",
        Value: -2
      });
    }
  },
  watch: {
    /**
     * Watch changes of selectedRecordCategoryId
     * @param id
     * @return {Promise<void>}
     */
    selectedRecordCategoryId: {
      handler: async function(id) {
        try {
          if (!this.menuFilterVisible) {
            return;
          }
          await this.setSearchCategory(searchableNumericFieldValue(id));

          // Set Category Searchable Fields
          this.categorySearchableFields = [];
          this.categoryFields?.forEach(field => {
            this.categorySearchableFields.push(
              this.createSearchableField(field)
            );

            if (this.isFieldRangeSearchable(field)) {
              this.categorySearchableFields.push(
                this.createSearchableField(field, null, true)
              );
            }
          });

          if (this.userFieldModel) {
            this.loadSearchRecordFields(this.userFieldModel);
            this.userFieldModel = undefined;
          }

          // console.log(
          //   `${this.$options.name} this.categoryFields:`,
          //   this.categoryFields
          // );
          // console.log(
          //   `${this.$options.name} this.categorySearchableFields:`,
          //   this.categorySearchableFields
          // );
          // console.log(`${this.$options.name} this.formFields:`, this.formFields);
        } catch (e) {
          console.error(e);
        }
      },
      immediate: true // This ensures that the watcher is called immediately when selectedRecordCategoryId changes
    },

    /**
     * Watch menuFilterVisible, and perform actions correspondingly (cleanup, ...)
     */
    menuFilterVisible() {
      this.filterError = undefined;
    },

    // Watcher for filterSearchableRecordProps
    filterSearchableRecordProps: {
      handler() {
        this.updateEnableUniversalSearch();
      },
      immediate: true // Ensure it's called initially
    },

    // Watcher for filterSearchableRecordCategoryFields
    filterSearchableRecordCategoryFields: {
      handler() {
        this.updateEnableUniversalSearch();
      },
      immediate: true
    },

    // Watcher for filterSearchableFlyingFields
    filterSearchableFlyingFields: {
      handler() {
        this.updateEnableUniversalSearch();
      },
      immediate: true
    },

    // Watcher for hasFullTextCriteria
    hasFullTextCriteria: {
      handler() {
        this.updateEnableUniversalSearch();
      },
      immediate: true
    },

    // Watcher for fieldCreatedFrom
    fieldCreatedFrom: {
      handler() {
        this.updateEnableUniversalSearch();
      },
      immediate: true
    },

    // Watcher for fieldCreatedFrom
    fieldCreatedTo: {
      handler() {
        this.updateEnableUniversalSearch();
      },
      immediate: true
    },

    // Watcher for enableUniversalSearch
    enableUniversalSearch() {
      if (!this.enableUniversalSearch) {
        //this.fieldUniversalSearch = this.createUniversalSearchField(); //reset global search field if universal search disabled
      }
    }
  }
};
