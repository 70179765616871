/**
 * DataTable actions header Name
 * @type {Readonly<{actions: string}>}
 */
const headerName = Object.freeze({
  actions: "actions"
});

/**
 * DataTable actions header Text
 * @type {Readonly<{actions: string}>}
 */
const headerText = Object.freeze({
  actions: "Actions"
});

export { headerName, headerText };
