// vuex
import { createNamespacedHelpers } from "vuex";
const { mapState, mapGetters, mapActions } = createNamespacedHelpers("user");

import { principalMixin } from "@/mixins/shared/user/principalMixin";
import { systemOperations } from "@/model/user/userModel";

/**
 * Encapsulate Questys current user
 */
export const userMixin = {
  mixins: [principalMixin],
  computed: {
    ...mapState({
      currentUser: "user",
      repositories: "repositories",
      categoryTypes: "categoryTypes"
    }),
    ...mapGetters({
      actorName: "actorName",
      userCategories: "categories",
      userFields: "fields",
      formFields: "formFields",
      findUserField: "findField",
      findFormField: "findFormField",
      countUserFields: "countFields",
      isPersistentField: "isPersistentField",
      isSystemField: "isSystemField",
      countUserCategories: "countCategories",
      findCategory: "findCategory",
      isAllowedViewField: "isAllowedViewField",
      createRecordCategories: "createRecordCategories",
      createRecordCategoriesByCategoryType:
        "createRecordCategoriesByCategoryType",
      updateRecordCategories: "updateRecordCategories",
      updateRecordCategoriesByCategoryType:
        "updateRecordCategoriesByCategoryType",
      isAllowedModifyField: "isAllowedModifyField",
      isAllowedSystemOperation: "isAllowedSystemOperation",
      isUserLoggedIn: "isUserLoggedIn",
      repository: "repository"
    }),

    /**
     * compute whether current user is allowed Access WF Projects
     * @return {boolean} true if current user is allowed to Access/Mange workflow Projects
     */
    allowedAccessWFProjects() {
      return !!this.isAllowedSystemOperation(systemOperations.accessWFProjects);
    },

    /**
     * compute whether current user is allowed to access Agenda Clerk Module
     * @return {boolean} true if current user is allowed to access/Mange workflow Projects
     */
    allowedAccessClerkModule() {
      return !!this.isAllowedSystemOperation(
        systemOperations.accessClerkModule
      );
    }

    /**
     * insert here allowed systemOperations on demand
     * ...
     */
  },
  methods: {
    ...mapActions({ setFormField: "setFormField" })
  }
};
